.square {
  color: white;
  font-family: Arial;
  text-align: center;
  border-color: white;
  margin: 10px;
  background-color: #282c34;
  border-radius: 25px;
  width: 360px;
  height: 160px;
}